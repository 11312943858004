import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SigninComponent implements OnInit {
  phone_no: string;
  name: string;
  windowRef: any;
  code: any;
  applicationVerifier: auth.RecaptchaVerifier;
  test: string;
  showOtpBox: boolean;
  successMsg: string;
  errorMsg = '';
  error: any;

  constructor(private afAuth: AngularFireAuth,
              private router: Router,
              private cd: ChangeDetectorRef,
              private route: ActivatedRoute) {
    this.afAuth.user.subscribe(data => {
      // alert('trying to redirect');
      if (data) {
        this.router.navigateByUrl(this.returnUrl);
      }
    });
  }

  returnUrl: any = '/events';
  confirmationResult: any;

  ngOnInit() {
    this.route.queryParams.subscribe(param => {
      if (param.returnUrl) {
        this.returnUrl = param.returnUrl;
      }
    });
    this.test = 'testValue' + Math.random();
    this.windowRef = this.windowRefObj;
    this.windowRef.applicationVerifier = new auth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
      callback: (response) => {
        this.signInSubmit();
      }
    });
    // this.windowRef.applicationVerifier = new auth.RecaptchaVerifier('recaptcha-container');
    this.windowRef.applicationVerifier.render().then((widgetId)=>{
      // this.windowRef.recaptchaWidgetId = widgetId;
      // this.updateSignInButtonUI();
    });


  }


  login() {
    this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()).then(function(result) {
      document.location.reload();
    });
  }

  get windowRefObj() {
    return window;
  }

  signInSubmit() {
    let mobileNo = '+91' + this.phone_no;
    this.afAuth.auth.signInWithPhoneNumber(mobileNo, this.windowRef.applicationVerifier).then((result) => {
      this.windowRef.confirmationResult = result;
      this.showOtpBox = true;
      this.successMsg = 'Otp successfully sent on provided number.'
      this.cd.detectChanges();
    }).catch(function(error) {
      console.log(error);
      alert(error.message);
      this.error = error.message;
      console.log(this.error);
    });
  }

  verifyPhone() {
    console.log(this.windowRef.confirmationResult);
    this.windowRef.confirmationResult.confirm(this.code).then((result) => {
      console.log(result);
      result.user.updateProfile({
        displayName: this.name
      }).then(function () {
        document.location.reload();
      }).catch(function (error) {
      });
    }).catch(function(error) {
      alert(error.message);
    });
  }


}
