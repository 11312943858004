import { AuthGuard } from './guard/auth.guard';
import { AngularFireAuthGuard, AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { environment } from './../environments/environment.prod';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
// import {BrowserModule, Title, HAMMER_GESTURE_CONFIG} from "@angular/platform-browser";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './auth/signin/signin.component';
import { VoteComponent } from './vote/vote.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { VoteDashboardComponent } from './vote-dashboard/vote-dashboard.component';
import { AskQuestionComponent } from './ask-question/ask-question.component';
import { AudienceQuestionsComponent } from './audience-questions/audience-questions.component';
import { EventComponent } from './event/event.component';
import { EventsComponent } from './events/events.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { ImageMagnifierComponent } from './image-magnifier/image-magnifier.component';


@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    VoteComponent,
    VoteDashboardComponent,
    AskQuestionComponent,
    AudienceQuestionsComponent,
    EventComponent,
    EventsComponent,
    AdminLoginComponent,
    ImageMagnifierComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
  ],
  exports:[ImageMagnifierComponent],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
