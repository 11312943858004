export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDA5Wd7a7bNCwCk709UWoCDZjVgIak7ck8",
    authDomain: "d2c-votings.firebaseapp.com",
    databaseURL: "https://d2c-votings.firebaseio.com",
    projectId: "d2c-votings",
    storageBucket: "d2c-votings.appspot.com",
    messagingSenderId: "982877398852",
    appId: "1:982877398852:web:f36e194203fece7a831911",
    measurementId: "G-CWHD286LLD"
  }
};
