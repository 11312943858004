import { AdminLoginComponent } from './admin-login/admin-login.component';
import { EventsComponent } from './events/events.component';
import { EventComponent } from './event/event.component';
import { AskQuestionComponent } from './ask-question/ask-question.component';
import { AuthGuard } from './guard/auth.guard';
import { VoteComponent } from './vote/vote.component';
import { SigninComponent } from './auth/signin/signin.component';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToEvents = () => redirectLoggedInTo(['event/tupx']);
const redirectLoggedInToAdmin = () => redirectLoggedInTo(['admin']);
const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);

const appRoutes: Routes = [

  // {
  //   path: '', redirectTo: 'event/tup2024', pathMatch: 'full'
  // },
  {
    path: '', redirectTo: 'event/tupx', pathMatch: 'full'
  },
  {
    path: 'event/:event_id/vote/:activity_id', component: VoteComponent, canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'event/:event_id/ask-question/:activity_id', component: AskQuestionComponent, canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'event/:id', component: EventComponent, canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'events', component: EventsComponent, canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'login', component: SigninComponent, canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToEvents }
  },
  {
    path: 'adminlogin', component: AdminLoginComponent, canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToAdmin }
  },

  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: adminOnly }},
  { path: '**', redirectTo: 'event/tupx', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
