import { Component, OnInit, Renderer2 } from '@angular/core';
import { flatMap, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { animate, group, keyframes, query, stagger, state, style, transition, trigger, animateChild } from '@angular/animations';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.scss'],
  animations: [
    trigger('slideInAnimation', [
      transition(':enter', [
        animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)', keyframes([
          style({ transform: 'translateX(200%)', opacity: 0, offset: 0 }),
          style({ transform: 'translateX(0)', opacity: 1, offset: 1 }),
        ]))
      ]),
      transition(':leave', [
        animate('200ms cubic-bezier(0.4, 0.0, 1, 1)', keyframes([
          style({ transform: 'translateX(0)', opacity: 1, offset: 0 }),
          style({ transform: 'translateX(200%)', opacity: 0, offset: 1 }),
        ]))
      ])
    ])
  ]
})
export class AskQuestionComponent implements OnInit {
  event_id: any;
  activity_id: any;
  basePath: string;
  categories: any;
  alreadyVoted: boolean;

  activity: any;

  user: any;
  userId: any;
  userQuestions: any;
  feedback: any;
  currentCategory: any;
  loader = false;

  constructor(public router: Router,
    public route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    public db: AngularFirestore,
    public renderer: Renderer2) {
  }

  showInfo = false;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params.event_id && params.activity_id) {
        this.event_id = params.event_id;
        this.activity_id = params.activity_id;
        this.basePath = 'events/' + this.event_id + '/activities/' + this.activity_id;
        this.getActivity();
        this.getQuestionCategories();
        this.fetchUser();
      }
    });
  }

  getActivity() {
    let activity = this.db.doc(this.basePath).valueChanges();
    activity.subscribe((item: any) => {
      this.activity = item;
    });
  }

  fetchUser() {
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.userId = user.uid;
        this.user = user;
        const status = this.getUserStatus();
        status.subscribe((data: any) => {
          this.userQuestions = data;
        });
      } else {
        this.router.navigateByUrl('/login');
      }
    });
  }

  getQuestionCategories() {
    this.categories = this.db.collection(this.basePath + '/categories', ref => ref.orderBy('sort_order'))
      .snapshotChanges().pipe(map((result: any) => {
        const newResult = result.map(a => {
          const data = a.payload.doc.data();
          this.loader = true;
          const id = a.payload.doc.id;
          const ref = a.payload.doc.ref;
          const status = true;
          return { id, ref, ...data, status };
        });
        return newResult;
      }));
  }

  getUserStatus() {
    const user = this.db.collection(this.basePath + '/questions', ref => ref.where('user_id', '==', this.userId))
      .snapshotChanges().pipe(map((result: any) => {
        const newResult = result.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          const ref = a.payload.doc.ref;
          const status = true;
          return { id, ref, ...data, status };
        });
        if (newResult.length < 1) {
          return [];
        }
        return newResult;
      }));
    return user;
  }

  giveFeedback() {
    let category = this.currentCategory;
    const RatingData = {
      user_id: this.userId,
      user_name: this.user.displayName,
      item_id: category.id,
      item_name: category.name,
      feedback: this.feedback,
    };
    this.addQuestion(category.ref, RatingData);
    this.feedback = null;
  }

  addQuestion(votingRef, rating) {
    let ratingRef = this.db.firestore.collection(this.basePath + '/questions').doc();
    let batch = this.db.firestore.batch();
    // batch.update(votingRef, {
    //   feedbackCount: firestore.FieldValue.increment(1),
    // });
    //sharding
    let shardId = Math.floor(Math.random() * 10).toString();
    let shardRef = votingRef.collection('feedback_shards').doc(shardId);
    batch.set(shardRef, { count: firestore.FieldValue.increment(1) });
    //sharding end

    batch.set(ratingRef, rating);
    batch.commit();
  }

  getUserQuestions(category) {
    let questions = this.userQuestions.filter((item) => item.item_id == category.id);
    return questions;
  }

  deleteQuestion(question) {
    let category = this.currentCategory;
    let batch = this.db.firestore.batch();
    // batch.update(category.ref, {
    //   feedbackCount: firestore.FieldValue.increment(-1),
    // });

    //sharding
    const shardId = Math.floor(Math.random() * 10).toString();
    const shardRef = category.ref.collection('feedback_shards').doc(shardId);
    batch.set(shardRef, { count: firestore.FieldValue.increment(-1) });
    //sharding end
    batch.delete(question.ref);
    batch.commit();
  }

  showInfobox(event, category) {
    event.stopPropagation();
    this.currentCategory = category;
    this.showInfo = !this.showInfo;
    this.renderer.addClass(document.body, 'prevent-scroll');
  }

  hideInfobox() {
    this.showInfo = false;
    this.renderer.removeClass(document.body, 'prevent-scroll');
  }

}
