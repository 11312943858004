import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnInit } from '@angular/core';
import { AnyFn } from '@ngrx/store/src/selector';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  activities:any;
  event_id: any;
  loader=false;
  isAllDeactive = true;
  logoUrl:any;
  constructor(private db:AngularFirestore, private route:ActivatedRoute) {
   }

  ngOnInit() {
    this.route.params.subscribe((params:any)=>{
      if(params['id']){
        this.event_id = params['id'];
        this.activities = this.db.collection('events/'+this.event_id+'/activities').snapshotChanges().pipe(map((result: any) => {
          let newResult = result.map(a => {
            const data = a.payload.doc.data();
            if (data.active) {
              this.isAllDeactive = false;
            }
            if(data.logoUrl){
              this.logoUrl=data.logoUrl
            }
            this.loader = true;
            const id = a.payload.doc.id;
            const ref = a.payload.doc.ref;
            const status = true;
            return { id, ref, ...data, status };
          });
          return newResult;
        }));
      }
    });

  }

}
