import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnInit } from '@angular/core';
// import { auth, firestore, database } from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';

@Component({
  selector: "app-vote",
  templateUrl: "./vote.component.html",
  styleUrls: ["./vote.component.scss"],
})
export class VoteComponent implements OnInit {
  votingItems: any;
  userId: any;
  user: any;
  votingDone: boolean;
  selectedItems: any = [];
  itemsList: any;
  openBox: boolean;
  alreadyVoted: boolean;
  activity_id: any;
  event_id: any;
  basePath: string;
  activity: any;
  userVotes: any;
  votingItemsList: any;
  selectedImage = { avatar: 'path/to/original/image.jpg' };
  // selectedOpportunities: any;
  showMagnifiedImage: boolean = false;
  previewImage = null;

  constructor(
    public route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    public db: AngularFirestore
  ) {
    // this.db.firestore.settings({ persistence: false });
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params.event_id && params.activity_id) {
        this.event_id = params.event_id;
        this.activity_id = params.activity_id;
        this.basePath =
          "events/" + this.event_id + "/activities/" + this.activity_id;
        this.fetchUser();
        this.getActivity();
      }
    });
  }

  openMagnifiedImage(item) {
    this.showMagnifiedImage = true;
    this.previewImage = item.avatar;
    // this.selectedImage = item;
  }

  closeBox(e){
    this.showMagnifiedImage = e;
    this.previewImage = null;
  }


  getActivity() {
    let activity = this.db.doc(this.basePath).valueChanges();
    activity.subscribe((item: any) => {
      this.activity = item;
    });
  }

  fetchUser() {
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.userId = user.uid;
        this.user = user;
        const status = this.getUserStatus();
        status.subscribe((data: any) => {
          this.userVotes = data;
          if (!this.votingItems) {
            this.getVotingItems();
          }
        });
      } else {
        // this.router.navigateByUrl('/login');
      }
    });
  }

  getVotingItems() {
    this.votingItems = this.db
      .collection(this.basePath + "/votingItems")
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((result: any) => {
          const newResult = result.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            const ref = a.payload.doc.ref;
            const status = true;
            let selected = false;
            let ratingRef: any;
            let selectedItem = this.userVotes.filter((item) => {
              console.log(item.vote_item_id);
              return item.vote_item_id === id;
            });
            if (selectedItem[0]) {
              selected = true;
              ratingRef = selectedItem[0].ref;
            }
            return { id, ref, ...data, status, selected, ratingRef };
          });
          return newResult;
        })
      );
  }

  getUserStatus() {
    const user = this.db
      .collection(this.basePath + "/votes", (ref) =>
        ref.where("user_id", "==", this.userId)
      )
      .snapshotChanges()
      .pipe(
        map((result: any) => {
          const newResult = result.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            const ref = a.payload.doc.ref;
            const status = true;
            return { id, ref, ...data, status };
          });
          if (newResult.length < 1) {
            return [];
          }
          return newResult;
        })
      );
    return user;
  }

  vote() {
    this.alreadyVoted = true;
  }

  addVoting(element) {
    // if(ele)
    const RatingData = {
      user_id: this.userId,
      vote_item_id: element.id,
      item_name: element.name,
      rating: 1,
    };

    this.addRating(element.ref, RatingData, element);
  }

  addRating(votingRef, rating, element?) {
    let key = this.userId + "x" + element.id;
    let ratingRef = this.db.firestore
      .collection(this.basePath + "/votes")
      .doc(key);
    return this.db.firestore
      .runTransaction((transaction) => {
        return transaction.get(ratingRef).then((res) => {
          console.log(res);
          if (!res.exists) {
            // const shardId = Math.floor(Math.random() * 10).toString();
            // const shardRef = votingRef.collection('voting_shards').doc(shardId);
            transaction.set(ratingRef, rating);
            // transaction.set(shardRef, { count: firestore.FieldValue.increment(1) });
          }
        });
      })
      .then(() => {
        element.selected = true;
        element.loading = false;
      })
      .catch((err) => {
        element.selected = false;
        element.loading = false;
      });
  }

  goBack() {
    if (this.activity.logout) {
      this.logout();
    } else {
      // this.router.navigate(["event", this.event_id]);
      // document.location.reload();
    }
  }

  logout() {
    this.afAuth.auth.signOut().then(() => {
      document.location.reload();
    });
  }

  removeSelectedItem(item) {
    if (item) {
      let key = this.userId + "x" + item.id;
      let ratingRef = this.db.firestore
        .collection(this.basePath + "/votes")
        .doc(key);
      let votingRef = item.ref;
      return this.db.firestore
        .runTransaction((transaction) => {
          return transaction.get(ratingRef).then((res) => {
            if (res.exists) {
              // const shardId = Math.floor(Math.random() * 10).toString();
              // const shardRef = votingRef.collection('voting_shards').doc(shardId);
              transaction.delete(ratingRef);
              // transaction.set(shardRef, { count: firestore.FieldValue.increment(-1) });
            }
          });
        })
        .then(() => {
          item.selected = false;
          item.loading = false;
        })
        .catch((err) => {
          item.selected = true;
          item.loading = false;
        });
    }
  }

  compSelected(event, item) {
  this.selectedImage = item;
  // this.showMagnifiedImage = true;
    if (!item.loading) {
      item.loading = true;
      if (item.selected) {
        // item.selected = false;
        this.removeSelectedItem(item);
        console.log("Remove Item");
      } else {
        if (this.userVotes.length < 2) {
          this.addVoting(item);
          item.selected = true;
          this.userVotes.push(item);``
        } else{
          item.loading = false;
          item.selected = false;
          event.preventDefault();
          alert("You've already selected maximum allowed votes");
        }
        // item.selected = true;
        // alert(item.loading);
        console.log("Push Item");


        // if (this.userVotes.length < 2) {

        // } else {

        // }
      }
    }
    // console.log(item);
  }

  // compSelected(event, item) {
  //   // this.canVote = false;
  //   if (!this.votingClosed) {
  //     if (!this.userLoggedIn) {
  //       this.login();
  //     } else {
  //       // if (this.canVote) {
  //       console.log(this.canVote);
  //       console.log(item);
  //       console.log(item.selected);
  //       if (item.selected) {
  //         this.removeSelectedItem(item);
  //         console.log("Remove Item");
  //       } else {
  //         console.log("Push Item");
  //         item.selected = true;
  //         if (this.selectedOpportunities.length < 5) {
  //           this.selectedOpportunities.push(item);
  //         } else {
  //           item.selected = false;
  //           event.preventDefault();
  //           alert("You've already selected maximum allowed competitions");
  //         }
  //       }
  //       console.log(item);
  //       this.openBox = this.selectedOpportunities.length > 0;
  //       console.log(this.selectedOpportunities);
  //     }
  //   }else{
  //     alert("Voting has been closed now.");
  //   }
  //   /*else {
  //     if (item.d2c_comp_url) {
  //       window.open('/' + item.d2c_comp_url, '_blank');
  //     }
  //   }*/
  // }

  closeActionBar() {
    this.votingItems.forEach((element) => {
      element.selected = element.selected ? false : element.selected;
    });
    this.selectedItems = [];
    this.openBox = false;
  }
}
