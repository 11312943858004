import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-image-magnifier',
  templateUrl: './image-magnifier.component.html',
  styleUrls: ['./image-magnifier.component.scss']
})
export class ImageMagnifierComponent {
  @Input() magnifiedImageUrl: string = '';
  @Output() closePanel = new EventEmitter<any>();
  showMagnifiedImage: boolean = true;

  openMagnifiedImage() {
    this.showMagnifiedImage = true;
  }

  closeMagnifiedImage() {
    // this.showMagnifiedImage = false;
    this.closePanel.emit(false);
  }
}
