import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  events:any;
  constructor(private afAuth:AngularFireAuth, private db:AngularFirestore) {
    this.events = this.db.collection('events').snapshotChanges().pipe(map((result: any) => {
      let newResult = result.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        const ref = a.payload.doc.ref;
        const status = true;
        return { id, ref, ...data, status };
      });
      return newResult;
    }));
   }

  ngOnInit() {
  }

  logout() {
    this.afAuth.auth.signOut().then(() => {
      document.location.reload();
    });
  }


}
